import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

import sliderImg1 from "../../static/images/facilities-img1.png";
import sliderImg2 from "../../static/images/facilities-img2.png";
import sliderImg3 from "../../static/images/facilities-img3.png";
import sliderImg4 from "../../static/images/facilities-img4.png";
import sliderImg5 from "../../static/images/facilities-img5.png";

const Facilities = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    centerMode: true,
    centerPadding: "250px",
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,

          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,

          centerPadding: "70px",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1,
          centerPadding: "40px",
        },
      },
    ],
  };

  const sliderData = [
    {
      img: sliderImg1,
    },
    {
      img: sliderImg2,
    },
    {
      img: sliderImg3,
    },
    {
      img: sliderImg4,
    },
    {
      img: sliderImg5,
    },
  ];

  return (
    <section className="w-full mb-[120px] lg:mb-[150px]">
      <div className="w-full px-[5%] text-center">
        <h2 className="font-bold text-4xl leading-[44px] text-gray_900 fontFamily2 mb-[36px] lg:mb-[60px]">
          Our State-of-the-Art Facility
        </h2>
      </div>
      <div className="facilities_container">
        <Slider {...settings}>
          {sliderData.map((data) => {
            return (
              <div className="w-full px-[8px] h-[499px] md:h-[550px] items-center justify-center outline-none mb-[43px] lg:mb-[60px] slidering-img">
                <img
                  className="w-full h-[409px] md:h-[500px]"
                  src={data.img}
                  alt="card-img"
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Facilities;

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="absolute left-[10%] top-auto bottom-[-19px] md:bottom-[-18px] lg:bottom-auto lg:top-[50%] lg:translate-x-[-0%] opacity-[.9] hover:opacity-[1] lg:translate-y-[-50%] z-10"
    >
      <StaticImage
      placeholder="none"
        src="../../static/svgs/testimonail-arrow-right.svg"
        alt="right-arrow"
        className="cursor-pointer"
      />
    </button>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="absolute block  right-[10%] top-auto bottom-[-19px] md:bottom-[-18px] lg:bottom-auto lg:top-[50%] lg:translate-x-[-0%] lg:translate-y-[-50%] opacity-[.9] hover:opacity-[1] z-10"
    >
      <StaticImage
      placeholder="none"
        src="../../static/svgs/testimonail-arrow-left.svg"
        alt="left-arrow"
        className="cursor-pointer"
      />
    </button>
  );
};
