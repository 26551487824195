import React from "react";
import aboutImg1 from "../../static/svgs/wellness-icon.svg";
import aboutImg2 from "../../static/svgs/health-icon.svg";
import aboutImg3 from "../../static/svgs/knowledgeable-icon.svg";

const sectionData = [
  {
    img: aboutImg1,
    heading: "Personalized Wellness & Recovery",
    text: "We craft an intuitive, personalized treatment strategy to help you use your body's natural healing abilities.",
  },
  {
    img: aboutImg2,
    heading: "Healthy, Alternative Practices",
    text: "We’re a forward-thinking wellness studio that takes a holistic approach to managing your pain and discomfort.",
  },
  {
    img: aboutImg3,
    heading: "Knowledgeable Experts",
    text: "We’re a team of highly trained wellness specialists with expertise in a variety of therapeutic techniques.",
  },
];

const HighkeySection = () => {
  return (
    <section className="w-4/5 mx-auto mb-[90px] lg:mb-[109px]">
      <div className="w-full text-center mb-16">
        <h2 className="fontFamily2 text-gray_900 text-4xl leading-[44px] font-bold ">Why HighKey?</h2>
      </div>
      <div className="grid gap-20    grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {sectionData.map((data) => {
          return (
            <div className="w-full flex flex-col items-center">
              <img src={data.img} alt="card-icon" />
              <h6 className="fontFamily2 text-gray_900 text-lg text-center leading-[24px] m-3">{data.heading}</h6>
              <p className="text-typography_body fontFamily1 text-base text-center leading-7">{data.text}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HighkeySection;
