import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../Button";

const OurPhilosophySection = () => {
  return (
    <section className="w-[90%] lg:w-4/5 mx-auto flex flex-col lg:flex-row items-center gap-[45px] lg:gap-20 mb-[90px] lg:mb-[128px]">
      <div className="w-full lg:w-2/4">
        <StaticImage
        placeholder="none"
          src="../../static/images/our-philosophy-section.png"
          alt="our-philosophy-section-img"
        />
      </div>
      <div className="w-full lg:w-2/4">
        <h2 className="font-bold text-[35px]  lg:text-4xl leading-[43px] lg:leading-[44px] mb-6 text-gray_900 fontFamily2 ">
          Our Philosophy
        </h2>
        <p className="fontFamily1 text-typography_body text-base leading-7 font-normal mb-6">
            At HighKey Balance, we are strong advocates of the body's innate healing power. When you step into our studio, you're not just a client; you're an individual deserving of customized attention and genuine care from our wellness professionals. Our team of recovery specialists not only provide treatments but also empower you with the knowledge to maintain a proactive approach to your well-being even beyond our doors.
        </p>
        <a
          href="https://www.fresha.com/a/highkey-balance-washington-1140-19th-street-nw-k658km4x/booking"
        >
          <Button
            texts={"Book Now"}
            customClass={
              "uppercase text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em] w-[165px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
            }
          />
        </a>
      </div>
    </section>
  );
};

export default OurPhilosophySection;
