import React from "react";
import ReactPlayer from "react-player";
import Button from "../Button";
import vidoeImg from "../../static/images/video-img.png";
import { StaticImage } from "gatsby-plugin-image";

const AboutHero = ({toggleFunc}) => {
  return (
    <header className="w-[90%] lg:w-4/5 mt-12 lg:mt-20  mx-auto">
      <div className="flex flex-col-reverse lg:items-center lg:flex-row gap-[45px] lg:gap-10 xl:gap-20  pt-[23px] lg:pt-[113px]">
        <div className="w-full lg:w-[35%]">
          <h1 className="font-bold text-[40px]  lg:text-[42px] leading-[49px] lg:leading-[51px]  fontFamily2 text-gray_900 mb-6">
            About Our All-In-One Wellness Studio
          </h1>
          <h3 className="text-gray_900 text-xl lg:text-3xl leading-[30px] fontFamily2 lg:leading-10 font-normal mb-6">Who We Are</h3>
          <p className="fontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
            HighKey Balance is your go-to destination for sports and clinical bodywork. We pride ourselves on providing tailored wellness and recovery treatments to athletes and active individuals from every walk of life.
          </p>
          <a href="https://www.fresha.com/a/highkey-balance-washington-1140-19th-street-nw-k658km4x/booking?menu=true&offerItems=sv%3A12427218&dppub=true&employeeId=1256270">
          <Button
          onClick={() => toggleFunc("consultation")}
            texts="Free Consultation"
            customClass="uppercase text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em] w-[250px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
          />
          </a>
        </div>
        <aside className="w-full lg:w-[65%]">
          <ReactPlayer
            className="videoplayer"
            controls
            playing
            light={vidoeImg}
            url={'https://youtu.be/xPSEBhS3K2Q'}
            playIcon={<PlayIcon />}
          />
        </aside>
      </div>
    </header>
  );
};

export default AboutHero;

const PlayIcon = () => {
  return (
    <>
      <StaticImage src="../../static/svgs/play-icon.svg" alt="play-icon" placeholder="none" />
    </>
  );
};
