import React from "react";
import AboutHero from "../components/AboutHero";
import BookingSection from "../components/BookingSection";
import Facilities from "../components/Facilities";
import HighkeySection from "../components/HighkeySection";
import OurGoalSection from "../components/OurGoalSection";
import OurPhilosophySection from "../components/OurPhilosophySection";
import RecoverySection from "../components/RecoverySection";
import Testimonial from "../components/Testimonial";
import Layout from "../components/Layout";
import Seo from "../components/SEO";

const META_DATA = {
  title: "Wellness Experts | Washington DC | HighKey Balance",
  description: `HighKey Balance wellness experts specialize in personalized wellness and recovery treatment for Washington, D.C., athletes, and others who train and play hard.`,
};

const AboutUs = () => {
  return (
    <Layout>
      <Seo {...META_DATA} />
      <AboutHero />
      <OurGoalSection />
      <OurPhilosophySection />
      <Facilities />
      <HighkeySection />
      <Testimonial />
      <RecoverySection />
      <BookingSection />
    </Layout>
  );
};

export default AboutUs;
