import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const OurGoalSection = () => {
  return (
    <section className="w-full lg:w-4/5  lg:ml-[7%] bg-black_typography mt-[128px] lg:mt-[190px] mb-[131px] lg:mb-[180px] h-[412px] lg:h-[412px] flex items-center relative">
      <div className="w-full">
        <StaticImage
        placeholder="none"
          src="../../static/svgs/decoration-symbol.svg"
          alt="bg-img"
          className="!absolute left-[27px] lg:left-[52px] top-[-70px] z-10"
        />
        <div className="absolute h-[412px] w-[90%] lg:w-full right-0 lg:right-[-50px] bg-[#E5E6FB] bottom-[-50px] z-[-1]"></div>
        <div className="w-[90%] mx-auto flex flex-col items-center">
          <h2 className="text-white text-center text-[35px] leading-[43px] xl:text-4xl xl:leading-[44px] font-bold mb-6 fontFamily2">
            Our Mission
          </h2>
          <p className="fontFamily1 text-center font-normal text-base leading-7 text-gray-50 w-full xl:w-[720px] mb-6 ">
            Our goal is straightforward—to allow you to move pain-free so you
            can reach your full potential, live the life you want, and do more
            of what you love.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurGoalSection;
